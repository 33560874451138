"use client";
import { cn } from "../utils/cn";
import { motion } from "framer-motion";
import type { HTMLAttributes, ReactNode } from "react";

export const ShinyRotatingBorderButton = ({
  children,
  className,
  ...props
}: Readonly<{ children: ReactNode; className?: string }> &
  HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={cn(
        "group relative overflow-hidden rounded-full bg-neutral-200 p-[2px] transition-transform active:scale-95 dark:bg-neutral-600",
        className,
      )}
      type="button"
      {...props}
    >
      <motion.span
        animate={{
          top: ["50%", "0%", "50%", "100%", "50%"],
          left: ["0%", "50%", "100%", "50%", "0%"],
        }}
        className="-translate-x-1/2 -translate-y-1/2 absolute z-10 size-10 transform-gpu blur-sm transition-transform duration-300 group-hover:scale-[4]"
        initial={{ top: 0, left: 0 }}
        transition={{
          duration: 6,
          ease: "linear",
          repeat: Number.POSITIVE_INFINITY,
        }}
      >
        <motion.span
          animate={{
            rotate: ["0deg", "360deg"],
          }}
          className="block size-full transform-gpu rounded-full"
          style={{
            background:
              "linear-gradient(135deg, #384650, #E72878, #39BFF0, #384650)",
          }}
          transition={{
            duration: 6,
            ease: "linear",
            repeat: Number.POSITIVE_INFINITY,
          }}
        />
      </motion.span>
      <span className="relative z-10 block rounded-full bg-white px-4 py-2 dark:bg-neutral-800">
        <motion.span
          animate={{
            backgroundImage: [
              "linear-gradient(90deg, #384650, #E72878, #39BFF0, #3668B1)",
              "linear-gradient(90deg, #3668B1, #384650, #E72878, #39BFF0)",
              "linear-gradient(90deg, #39BFF0, #3668B1, #384650, #E72878)",
              "linear-gradient(90deg, #E72878, #39BFF0, #3668B1, #384650)",
              "linear-gradient(90deg, #384650, #E72878, #39BFF0, #3668B1)",
            ],
          }}
          className="transform-gpu bg-clip-text text-neutral-600 text-base tracking-tighter transition-colors duration-500 group-hover:text-transparent dark:text-neutral-200"
          transition={{
            duration: 7,
            ease: "linear",
            repeat: Number.POSITIVE_INFINITY,
          }}
        >
          {children}
        </motion.span>
      </span>
    </button>
  );
};