import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Header from './components/Header';
import LandingPage from './components/landingpage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicePage';
import ContactPage from './components/ContactPage';
import Footer from './components/Footer';
import MentionsLegales from './components/MentionsLegales'; // Import the ConditionsGenerales component
import CookieBanner from './components/CookieBanner';

// Create a separate component for route tracking
function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <RouteTracker /> {/* Add the tracker component */}
      <div
        style={{
          backgroundImage: "url('/images/backgrounds/fond_total.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '100%',
          width: '100%',
        }}
      >
        {/* Include the header */}
        <Header />

        <Routes>
          {/* Define routes for each page */}
          <Route
            path="/"
            element={
              <>
                <section id="landing">
                  <LandingPage />
                </section>
                <section id="services">
                  <ServicesPage />
                </section>
                <section id="about">
                  <AboutPage />
                </section>
                <section id="contact">
                  <ContactPage />
                </section>
              </>
            }
          />
          <Route path="/MentionsLegales" element={<MentionsLegales />} />
        </Routes>

        {/* Include the footer */}
        <Footer />
        <CookieBanner /> {/* Add this line */}
      </div>
    </Router>
  );
}

export default App;
