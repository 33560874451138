import React from 'react';
import { Globe, Mail, Phone, Linkedin, MapPin, LucideProps } from 'lucide-react';

interface ContactItemProps {
  icon: React.ComponentType<LucideProps>;
  text: string;
  href: string;
}

const ContactItem: React.FC<ContactItemProps> = ({ icon: Icon, text, href }) => (
  <a
    href={href}
    className="flex items-center space-x-3 text-white hover:text-blue-400 transition-colors"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon size={24} />
    <span>{text}</span>
  </a>
);

export default function ContactPage() {
  return (
    <div id="contact" className="min-h-screen w-full flex flex-col items-center justify-center relative overflow-hidden px-4">
      <h1 className="text-3xl md:text-4xl font-light font-sharetechmono text-white mb-8 md:mb-12 tracking-wide z-10 text-center">
        Et pourquoi pas vous ?
      </h1>

      <div className="text-lg bg-gray-800 font-sharetech bg-opacity-60 border border-gray-700 rounded-lg p-4 md:p-8 w-full max-w-md z-10">
        <div className="space-y-4 mb-6">
          <ContactItem icon={Globe} text="junioresiee.com" href="https://junioresiee.com" />
          <ContactItem icon={Mail} text="contact@junioresiee.com" href="mailto:contact@junioresiee.com" />
          <ContactItem icon={Phone} text="01 45 92 66 70" href="tel:0145926670" />
          <ContactItem icon={Linkedin} text="Junior ESIEE" href="https://www.linkedin.com/company/junior-esiee" />
        </div>
        <div className="text-center text-white">
          <MapPin size={24} className="inline-block mr-2" />
          <address className="inline">2 Bd Blaise Pascal, 93160 Noisy-le-Grand</address>
        </div>
      </div>
    </div>
  );
}
