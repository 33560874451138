import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 bg-opacity-0 py-6 backdrop-blur-md transition-all duration-300">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-8">
        {/* Left: Logo */}
        <div className="flex items-center mb-4 md:mb-0">
          <img
            src="/images/Junior_ESIEE_logo.png"
            alt="Junior ESIEE Logo"
            className="h-20 w-15"
          />
        </div>

        {/* Right: Terms and Copyright */}
        <div className="text-sm text-gray-400 text-center md:text-right">
          <Link
            to="/MentionsLegales"
            className="hover:underline block md:inline"
          >
            Mentions Légales
          </Link>
          <span className="hidden md:inline mx-2">|</span>
          <span>© 2025 Junior ESIEE</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
