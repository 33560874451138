import React from 'react';
import Header from './Header'; // Import your header component

export default function MentionsLegales() {
  return (
    <div
      style={{
        minHeight: '100vh', // Ensure the container covers the full height of the viewport
        width: '100%', // Full width
        display: 'flex', // Flexbox to push content towards top
        flexDirection: 'column',
        backgroundImage: "url('/images/backgrounds/fond_about.png')", // Set background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Header */}
      <Header />

      {/* Main Content */}
      <div className="flex-grow container mx-auto px-4 py-16">
        <h1 className="text-4xl font-sharetechmono text-center mb-12 tracking-wide text-white mt-40">
          Mentions Légales
        </h1>
        
        <div className="bg-gray-800 bg-opacity-30 border border-gray-700 rounded-lg p-8 shadow-lg text-white">
          <h2 className="text-2xl mb-4">Définitions</h2>
          <p><strong>Client :</strong> tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des présentes conditions générales.</p>
          <p><strong>Prestations et Services :</strong> <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> met à disposition des Clients.</p>
          <p><strong>Contenu :</strong> Ensemble des éléments constituants l’information présente sur le Site, notamment textes, images, vidéos.</p>
          <p><strong>Informations clients :</strong> Les données personnelles susceptibles d’être détenues par <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> pour la gestion de votre compte, de la gestion de la relation client, et à des fins d’analyses et de statistiques.</p>
          
          <h2 className="text-2xl mt-8 mb-4">1. Présentation du site internet.</h2>
          <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
          <ul className="list-disc list-inside mb-4">
            <li><strong>Propriétaire :</strong> Junior ESIEE – 2 Bd Blaise Pascal, 93160 Noisy-le-Grand</li>
            <li><strong>Responsable publication :</strong> Junior ESIEE – contact@junioresiee.com</li>
            <li><strong>Hébergeur :</strong> OVH – 2 rue Kellermann, 59100 Roubaix</li>
          </ul>

          <h2 className="text-2xl mt-8 mb-4">2. Conditions générales d’utilisation du site et des services proposés.</h2>
          <p>Le Site constitue une œuvre de l’esprit protégée par les dispositions du Code de la Propriété Intellectuelle et des Réglementations Internationales applicables. Le Client ne peut en aucune manière réutiliser, céder ou exploiter pour son propre compte tout ou partie des éléments ou travaux du Site.</p>
          <p>L’utilisation du site <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> sont donc invités à les consulter de manière régulière.</p>
          
          <h2 className="text-2xl mt-8 mb-4">3. Description des services fournis.</h2>
          <p>Le site internet <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> a pour objet de fournir une information concernant l’ensemble des activités de la société.</p>
          <p>Junior ESIEE s’efforce de fournir sur le site <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
          
          <h2 className="text-2xl mt-8 mb-4">4. Limitations contractuelles sur les données techniques.</h2>
          <p>Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.</p>

          <h2 className="text-2xl mt-8 mb-4">5. Propriété intellectuelle et contrefaçons.</h2>
          <p>Junior ESIEE est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logos, vidéos, icônes et sons. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Junior ESIEE.</p>

          <h2 className="text-2xl mt-8 mb-4">6. Limitations de responsabilité.</h2>
          <p>Junior ESIEE ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a>, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p>

          <h2 className="text-2xl mt-8 mb-4">7. Gestion des données personnelles.</h2>
          <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
          <p>A l'occasion de l'utilisation du site <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a>, peuvent être recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a>, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.</p>

          <h2 className="text-2xl mt-8 mb-4">8. Droit applicable et attribution de juridiction.</h2>
          <p>Tout litige en relation avec l’utilisation du site <a href="https://junioresiee.com" className="text-blue-400">https://junioresiee.com</a> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.</p>

          <h2 className="text-2xl mt-8 mb-4">9. Les principales lois concernées.</h2>
          <ul className="list-disc list-inside">
            <li>Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.</li>
            <li>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</li>
          </ul>

          <h2 className="text-2xl mt-8 mb-4">10. Lexique.</h2>
          <ul className="list-disc list-inside">
            <li><strong>Utilisateur :</strong> Internaute se connectant, utilisant le site susnommé.</li>
            <li><strong>Informations personnelles :</strong> « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
