import React from 'react';
import Slider from 'react-slick';
import { Brain, ShieldCheck, Database, Monitor, Cpu, LucideIcon } from 'lucide-react';
import { ShinyRotatingBorderButton } from './ShinyRotatingBorderButton';


// Import React Slick CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ServiceCardProps {
  title: string;
  subtitle: string;
  items: string[];
  icon: LucideIcon;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, subtitle, items, icon: Icon }) => (
  <div className="w-[450px] h-[350px] bg-gray-800 bg-opacity-30 border border-gray-700 rounded-lg p-6 flex-shrink-0 flex flex-col justify-between relative">
    <div>
      <h3 className="text-2xl font-semibold font-sharetechmono text-white mb-2">{title}</h3>
      <p className="text-xl text-blue-400 font-sharetech mb-4">{subtitle}</p>
      <ul className="text-lg text-gray-300 font-sharetech space-y-2">
        {items.map((item, index) => (
          <li key={index} className="flex items-start">
            <span className="mr-2">•</span>
            {item}
          </li>
        ))}
      </ul>
    </div>
    
    <div className="flex justify-center mt-4">
      <a 
        href="https://forms.gle/9BASeMSkTSdpCN316" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <ShinyRotatingBorderButton className="scale-90">
          On travaille ensemble ?
        </ShinyRotatingBorderButton>
      </a>
    </div>

    <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex-shrink-0">
      <Icon size={80} className="text-gray-500" />
    </div>
  </div>
);

const PartnerLogo: React.FC<{ name: string }> = ({ name }) => (
  <div className="bg-opacity-0 p-1 rounded-lg flex items-center justify-center">
    <img
      src={`/images/clients/${name.toLowerCase().replace(/ /g, '_')}.png`}
      alt={`${name} logo`}
      className="max-h-24"
    />
  </div>
);

export default function ServicesPage() {
  const services = [
    {
      title: "Cybersécurité",
      subtitle: "Protégez vos données",
      items: [
        "Mise en place de solutions de sécurité",
        "Surveillance et réponse aux incidents",
        "Formation et sensibilisation",
        "Audit de sécurité",
      ],
      icon: ShieldCheck,
    },
    {
      title: "Data Science",
      subtitle: "Exploitez vos données",
      items: [
        "Développement de solutions Big Data",
        "Collecte et stockage de données",
        "Optimisation des performances",
        "Analyse et visualisation",
      ],
      icon: Database,
    },
    {
      title: "Informatique",
      subtitle: "Créez votre site",
      items: [
        "Applications web sur mesure",
        "Développement de sites web",
        "Maintenance et support",
        "Optimisation SEO",
      ],
      icon: Monitor,
    },
    {
      title: "Electronique",
      subtitle: "Solutions sur mesure",
      items: [
        "Développement de systèmes embarqués",
        "Intégration de solutions",
        "Maintenance et support",
        "Prototypage et tests",
      ],
      icon: Cpu,
    },
    {
      title: "Intelligence Artificielle",
      subtitle: "Boostez vos performances",
      items: [
        "Développement de modèles personnalisés",
        "Reconnaissance faciale, vocale et d'objets",
        "Automatisation et Optimisation",
        "Assistants virtuels et Chatbot",
      ],
      icon: Brain,
    },
  ];

  const partners = ['CNRS', 'EDF', 'THALES', 'UNIVERSITY OF TWENTE', 'HOPITAUX PARIS', 'CREDIT AGRICOLE'];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px',
          centerMode: false
        }
      }
    ]
  };

  return (
    <div id="services" className="min-h-screen w-full flex flex-col items-center justify-center">
      <div className="relative w-full max-w-6xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-light text-center mb-12 tracking-wide text-white">
          Nos prestations
        </h1>

        {/* React Slick Slider with Center Mode */}
        <Slider {...settings}>
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              subtitle={service.subtitle}
              items={service.items}
              icon={service.icon}
            />
          ))}
        </Slider>

        <h2 className="text-4xl font-light text-center mt-44 mb-12 text-white">
          Ils nous ont fait confiance
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {partners.map((partner, index) => (
            <PartnerLogo key={index} name={partner} />
          ))}
        </div>
      </div>
    </div>
  );
}
