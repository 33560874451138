import React from 'react';
import { motion } from 'framer-motion';
import { ShinyRotatingBorderButton } from './ShinyRotatingBorderButton';

const MetricBox = ({ number, text }: { number: string; text: string }) => (
  <div className="text-center px-12 py-8 mx-9"> {/* Added mx-4 for horizontal margin */}
    <div className="text-4xl font-bold text-white">{number}</div>
    <div className="text-lg font-sharetech text-gray-300">{text}</div>
  </div>
);

const TimelineStep = ({ text }: { text: string }) => (
  <div className="flex flex-col items-center relative">
    <div className="w-4 h-4 border-2 bg-white border-white rounded-full bg-transparent z-10"></div>
    <div className="text-lg font-sharetech text-white mt-2 text-center">{text}</div>
  </div>
);

// Extract shared animation config
const gradientAnimation = {
  animate: {
    backgroundImage: [
      "linear-gradient(90deg, #384650, #E72878, #39BFF0, #3668B1)",
      "linear-gradient(90deg, #3668B1, #384650, #E72878, #39BFF0)",
      "linear-gradient(90deg, #39BFF0, #3668B1, #384650, #E72878)",
      "linear-gradient(90deg, #E72878, #39BFF0, #3668B1, #384650)",
      "linear-gradient(90deg, #384650, #E72878, #39BFF0, #3668B1)",
    ]
  },
  transition: {
    duration: 3,
    ease: "linear",
    repeat: Number.POSITIVE_INFINITY,
  }
};

// Reusable gradient line component
const GradientLine = ({ className }: { className: string }) => (
  <motion.div 
    className={`absolute h-0.5 ${className}`}
    animate={gradientAnimation.animate}
    transition={gradientAnimation.transition}
  />
);

const TimelineSection = () => (
  <>
    <h2 className="text-4xl font-light font-sharetechmono text-center mb-12 text-white">
      Comment se déroule une étude ?
    </h2>
    <div className="flex justify-between items-center mb-8 relative">
      <GradientLine className="top-2 left-0 right-0" />
      
      <div className="relative">
        <TimelineStep text="Définition des besoins" />
        <GradientLine className="top-2 left-0 w-1/2" />
      </div>
      
      <TimelineStep text="Proposition commerciale" />
      <TimelineStep text="Réalisation de l'étude" />
      
      <div className="relative">
        <TimelineStep text="Remise du livrable" />
        <GradientLine className="top-2 right-0 w-1/2" />
      </div>
    </div>
    
    <p className="text-center text-xl font-sharetechmono mt-16 mb-16 text-white">
      Nous vous accompagnons <span className="text-[#EC407A]">à chaque étape</span> pour que votre vision devienne réalité.
    </p>

    {/* Add Shiny Button */}
    <div className="flex justify-center mb-16">
      <a 
        href="https://forms.gle/9BASeMSkTSdpCN316" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <ShinyRotatingBorderButton className="scale-110">
          Allons y !
        </ShinyRotatingBorderButton>
      </a>
    </div>
  </>
);

const logos = [
  "/images/partenaires/cnje.png",
  "/images/partenaires/icn.png",
  "/images/partenaires/odoo.png",
  "/images/partenaires/societegenerale.png",
  "/images/partenaires/aa_esiee.png",
  "/images/partenaires/je_idf.png",
  "/images/partenaires/alten.png"
];

export default function AboutPage() {
  return (
    <div id="about" className="min-h-screen w-full flex flex-col items-center justify-center">
      <div className="relative w-full max-w-6xl mx-auto px-4 py-16">
        {/* Partners Section - Moved to top */}
        <h3 className="text-4xl font-light font-sharetechmono text-center mb-12 text-white">Nos partenaires</h3>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 mb-40">
          <div className="col-span-2 md:col-span-3 lg:col-span-3 flex justify-center flex-wrap gap-12">
            {logos.map((logo, i) => (
              <div key={i} className="w-[200px] h-[100px] flex items-center justify-center">
                <img
                  src={logo}
                  className="max-h-24 md:max-h-36 w-auto object-contain"
                  alt={`Partner logo ${i + 1}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <h1 className="text-4xl font-light font-sharetechmono text-center mb-12 tracking-wide text-white">Qui sommes-nous ?</h1>

        {/* Unified Metric Container */}
        <div className="flex flex-col md:flex-row justify-center items-center bg-opacity-40 rounded-2xl border border-gray-600 py-4 mb-16 shadow-lg backdrop-blur-sm">
          <MetricBox number="43" text="années d'expertise" />
          <MetricBox number="95%" text="clients satisfaits" />
          <MetricBox number="3500" text="étudiants mobilisables" />
        </div>

        {/* Main Description Container */}
        <div className="bg-opacity-40 border border-gray-600 rounded-2xl p-8 mb-28 shadow-lg backdrop-blur-sm">
          <div className="flex justify-center items-center mb-4"></div>
          <div className="flex justify-center items-center mb-4">
            <img src="/images/Junior_ESIEE_logo.png" alt="Junior ESIEE logo" height="50" width="150" className="mr-6" />
            <span className="text-2xl font-light text-white">x</span>
            <img src="/images/logo_esiee.png" height={50} width={150} alt="ESIEE Paris logo" className="ml-6" />
          </div>
          <p className="text-gray-300 text-xl font-sharetech leading-relaxed">
            Avec 120 ans d'expérience, ESIEE Paris a su se réinventer à chaque révolution technologique. C'est dans cette même dynamique que Junior ESIEE s'est créée avec pour mission de concevoir le monde à venir.
            Depuis l'électronique avec Marcel Dassault, fondateur de Dassault Aviation, jusqu'à l'Intelligence Artificielle avec Yann Le Cun, directeur de Meta AI et inventeur de l'apprentissage profond, nos étudiants se sont démarqués afin de répondre aux challenges d'hier et d'aujourd'hui.
            Dans la lignée de leurs prédécesseurs, les étudiants d'ESIEE Paris continuent de vouloir participer au développement du monde face aux enjeux de demain.
          </p>
        </div>

        {/* Timeline Section */}
        <TimelineSection />
      </div>
    </div>
    
  );
}
