// src/components/Header.tsx
import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ShinyRotatingBorderButton } from './ShinyRotatingBorderButton';
import { Menu, X } from 'lucide-react';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 z-20 bg-gray-900 bg-opacity-0 shadow-lg px-4 md:px-8 py-3 flex justify-between items-center text-white hover:backdrop-blur-md transition-all duration-300">
      {/* Logo */}
      <div className="flex items-center">
        <HashLink smooth to="/#landing">
          <img
            src="/images/Junior_ESIEE_logo.png"
            alt="Junior ESIEE Logo"
            className="h-20 w-22"
          />
        </HashLink>
      </div>

      {/* Mobile Menu Button */}
      <button 
        className="md:hidden"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Navigation Links */}
      <nav className={`
        ${isMenuOpen ? 'flex' : 'hidden'} 
        md:flex 
        absolute md:relative 
        top-full md:top-auto 
        left-0 md:left-auto 
        right-0 md:right-auto 
        flex-col md:flex-row 
        bg-gray-900 md:bg-transparent 
        md:pl-28 
        space-y-4 md:space-y-0 
        space-x-0 md:space-x-20 
        p-4 md:p-0
        text-lg font-medium
      `}>
        <HashLink smooth to="/#landing" className="hover:text-[#39BFF0] transition-colors">
          Home
        </HashLink>
        <HashLink smooth to="/#services" className="hover:text-[#39BFF0] transition-colors">
          Services
        </HashLink>
        <HashLink smooth to="/#about" className="hover:text-[#39BFF0] transition-colors">
          A propos
        </HashLink>
        <HashLink smooth to="/#contact" className="hover:text-[#39BFF0] transition-colors">
          Contact
        </HashLink>
      </nav>

      {/* Contact Button */}
      <div className="hidden md:flex items-center">
        <a 
          href="https://forms.gle/9BASeMSkTSdpCN316" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <ShinyRotatingBorderButton className="scale-100">
            Je concrétise mon projet
          </ShinyRotatingBorderButton>
        </a>
      </div>
    </header>
  );
}

export default Header;
