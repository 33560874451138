import React from 'react';
import { motion } from 'framer-motion';
import { ChevronsDown } from 'lucide-react';

interface ButtonProps {
  children: React.ReactNode;
  hoverColor: string;
  tooltip: string;
}

const Button: React.FC<ButtonProps> = ({ children, hoverColor, tooltip }) => (
  <div className="relative inline-block group">
    <motion.button
      className={`px-5 py-1 mx-2 text-sm text-white bg-transparent rounded-full border-2 border-gray-700 transition-colors ${hoverColor}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {children}
    </motion.button>
    {/* Tooltip */}
    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max max-w-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      <div className="bg-[#384650] bg-opacity-20 text-white text-sm font-sharetech rounded py-1 px-2">
      {tooltip}
      </div>
    </div>
  </div>
);

export default function LandingPage() {
  return (
    <div
      id="landing"
      className="min-h-screen flex flex-col items-center justify-center relative overflow-hidden"
    >
      {/* Header buttons */}
      <div className="mb-2 z-10">
        <Button
          hoverColor="hover:bg-[#E72878]"
          tooltip="À Junior ESIEE, nous sommes passionnés par l'humain et le partage des connaissances. Cette passion nous engage vis-à-vis des étudiants, des entreprises, de l'école qui investissent sur nous afin de construire ensemble un avenir durable."
        >
          Passion
        </Button>
        <Button
          hoverColor="hover:bg-[#FAFAFA] hover:text-black"
          tooltip="Chaque membre de Junior ESIEE est animé par cette ambition de faire toujours mieux. Cet 'esprit start-up' nous pousse chaque jour à entreprendre et à être force de proposition afin d'être à la hauteur de notre vision."
        >
          Ambition
        </Button>
        <Button
          hoverColor="hover:bg-[#39BFF0]"
          tooltip="L'innovation, élément moteur de notre école depuis 120 ans. Nos étudiants se forment pour être à la pointe de la technologie dans les domaines porteurs de ces prochaines années et se challengent sans cesse pour inscrire notre démarche vers le progrès."
        >
          Innovation
        </Button>
      </div>

      {/* Logo and text */}
      <div className="text-center z-10 flex items-center">
        <img src=".\images\logo_junior_sans_texte.png" alt="Logo Junior ESIEE" width="40" height="40" />
        <div className="ml-2 text-left">
          <img src=".\images\titre_junior.png" alt="Titre Junior" className="h-10" />
            <p className="text-2xl text-white font-sharetech leading-none mt-3">Concevoir le monde de demain</p>
        </div>
      </div>
      <div className="chevrons-down pt-16">
        <ChevronsDown size={52} color="#ffffff" strokeWidth={2.5} />
      </div>
    </div>
  );
}
